import { useEffect } from 'react';
import Head from 'next/head';

function Error({ statusCode }) {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      window.location.href = 'https://oops.cloud.sbs.co.kr/noname.html';
    }
  }, []);

  return (
    <p>
      <Head>
        <link
          rel="stylesheet"
          href={`${process.env.NEXT_PUBLIC_GLOBAL_CSS_PC_URL}?v=${process.env.NEXT_PUBLIC_BUILD_TIME}`}
        />
      </Head>
      {statusCode ? `서버 오류 발생(${statusCode})` : '클라이언트 오류 발생'}
    </p>
  );
}

export default Error;
